export default function plural(
    count: number,
    singular: string,
    three: string,
    five: string,
): string {
    if (count === 1) {
        return singular;
    }

    if (count < 5) {
        return three;
    }

    return five;
}
