<script setup lang="ts">
import GRecaptcha from '~/components/other/GRecaptcha.vue';
import type { ReferenceInterface } from '~/components/ui/Reference.vue';
import type { Profile } from '~/types/profileCard';
import { fileToBase64 } from '~/utils/client/fileToBase64';
import { error, success } from '~/utils/client/useToast';
import type { Reference } from '.prisma/client';

export interface ReferenceFormInterface {
    name: string;
    position: string;
    message: string;
}

const defaultState: ReferenceFormInterface = {
    name: '',
    position: '',
    message: '',
};

enum FormState {
    Default = 0,
    Filling = 1,
    Loading = 2,
    Success = 3,
    Error = 4,
}

const reference = ref<ReferenceInterface>(
    JSON.parse(JSON.stringify(defaultState)),
);
const emit = defineEmits(['submit', 'sent', 'errored']);
const { gtag } = useGtag();
const loading = ref<FormState>(FormState.Default);

const props = defineProps<{
    catalog: Profile;
}>();

const onSubmit = async (key: string) => {
    emit('submit', reference.value);
    // wait a second for recaptcha to finish - pretty dumb it's not awaitable
    await new Promise((resolve) => setTimeout(resolve, 3000));
    try {
        await $fetch<Reference>(`/api/references/${props.catalog.slug}`, {
            method: 'POST',
            body: JSON.stringify({
                ...reference.value,
                image: image.value,
                recaptchaToken: key,
            }),
        });
        gtag('event', 'reference_form', {
            event_category: 'engagement',
            event_label: 'reference_form',
        });
        success('Hodnocení bylo úspěšně odeslána.');
        reference.value = JSON.parse(JSON.stringify(defaultState));
        loading.value = FormState.Success;
        emit('sent');
    } catch (e) {
        gtag('event', 'reference_form_error', {
            event_category: 'engagement',
            event_label: 'reference_form_error',
        });
        error(
            'Při odesílání hodnocení došlo k chybě. Zkuste to prosím později znovu.',
        );
        loading.value = FormState.Error;
        emit('errored', e);
    }
};

const image = ref<string | null>(null);

const onImageChange = async (event: Event) => {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
    image.value = file ? await fileToBase64(file) : null;
};
</script>

<template>
    <div class="w-full overflow-hidden">
        <div v-if="loading === FormState.Default">
            <div class="text-center px-8 py-5 mx-8">
                <button @click="loading = FormState.Filling" class="btn btn-sm btn-primary animate-bounce">Přidat hodnocení</button>
            </div>
        </div>
        <GRecaptcha
            v-else-if="loading !== FormState.Success"
            :id="`reference-form${catalog.slug}`"
            :site-key="$config.public.recaptcha.v2SiteKey"
            :callback="onSubmit"
            :on-before-execute="() => loading = FormState.Loading"
        >
            <template #default="{ execute }">
                <form v-if="reference" @submit.prevent.stop="execute()" class="flex flex-col gap-3 justify-center px-2 md:px-16 my-8">
                    <input
                        type="text" placeholder="Jméno a příjmení" class="input input-bordered w-full input-sm"
                        v-model="reference.name" required
                    />
                    <input type="file" class="file-input file-input-bordered w-full file-input-sm" @change="onImageChange" />
                    <textarea
                        class="textarea textarea-bordered textarea-sm rounded-xl" placeholder="Hodnocení"
                        v-model="reference.message" required
                    ></textarea>
                    <div class="flex flex-row">
                        <input
                            type="text" placeholder="Pracovní pozice" class="input input-sm input-bordered w-full"
                            v-model="reference.position"
                        />
                        <button
                            type="submit"
                            :disabled="loading === FormState.Loading"
                            class="ms-3 input-sm btn btn-primary btn-sm w-1/4"
                            rel="nofollow"
                        >
                            <span v-if="loading === FormState.Loading" class="loading loading-spinner"></span>
                            <span v-else>Odeslat</span>
                        </button>
                    </div>
                    <div class="text-center text-xs text-gray-500 mt-2">
                        Chráněno službou reCAPTCHA. <a
                        class="link"
                        href="https://policies.google.com/privacy?hl=cs"
                        target="_blank"
                    >Ochrana soukromí</a> <a
                        class="link"
                        href="https://policies.google.com/terms?hl=cs"
                        target="_blank"
                    >Smluvní podmínky</a>
                    </div>
                </form>
            </template>
        </GRecaptcha>
        <div v-else>
            <div class="text-center border rounded-2xl px-16 py-5 mx-8">
                <h2 class="text-2xl mt-3">Děkujeme za hodnocení!</h2>
                <p class="mt-4">Vaše hodnocení bylo úspěšně odesláno a čeká na schválení.</p>
            </div>
        </div>
    </div>
</template>
