<script setup lang="ts">
import { formatDate } from '@vueuse/shared';
import HeroIcon from '~/components/ui/HeroIcon.vue';

export interface ReferenceInterface {
    image?: string;
    name: string;
    position: string;
    date?: string;
    message: string;
    note?: string;
}

defineProps<ReferenceInterface>();

const referenceName = ref<HTMLElement | null>(null);
const minBubbleWidth = computed(() => {
    if (!referenceName.value) {
        return 'initial';
    }

    return `calc(80px + ${referenceName.value.clientWidth}px)`;
});
</script>

<template>
    <div class="chat chat-start my-3">
        <div class="chat-image avatar">
            <div class="w-14 rounded-full">
                <img v-if="image" :alt="name" :src="image">
                <HeroIcon v-else class="m-auto" name="UserIcon" :size="18" />
            </div>
        </div>
        <div ref="referenceName" class="chat-header max-w-[80%] mb-5 md:mb-0">
            {{ name }}<span v-if="position">, {{ position }}</span>
        </div>
        <div class="chat-bubble relative md:min-w-[var(--minWidth)]" :style="{'--minWidth': minBubbleWidth}">
            <time class="absolute left-[1px] md:left-auto md:right-[1px] -top-[19px] md:-top-[17px] text-xs text-base-content opacity-50">{{ formatDate(new Date(date), 'DD.MM.YYYY') }}</time>
            {{ message }}
        </div>
        <div class="chat-footer opacity-50">
            {{ note }}
        </div>
    </div>
</template>

<style scoped lang="postcss">

</style>
