<script setup lang="ts">
import type { Ref } from 'vue';
import GRecaptcha from '~/components/other/GRecaptcha.vue';
import FavoriteButton from '~/components/ui/FavoriteButton.vue';
import type ReferenceInterface from '~/components/ui/Reference.vue';
import ReferenceForm from '~/components/ui/ReferenceForm.vue';
import References from '~/components/ui/References.vue';
import type { Filter } from '~/types/filter';
import type { Profile } from '~/types/profileCard';
import plural from '~/utils/client/plural';
import { socialIcons } from '~/utils/client/socialIcons';
import { updatedFiltersWithTags } from '~/utils/client/useFilters';
import { error } from '~/utils/client/useToast';
import { type VocabularyItem, useTooltips } from '~/utils/client/useTooltips';

const props = withDefaults(
    defineProps<{
        assistant: Profile;
    }>(),
    {},
);

const assistantFilteredTags = ref<Filter[]>([]);
const assistantFilteredTagKeys = ref<string[]>([]);
const assistantLinks = ref<{ url: string; name: string }[]>([]);
const socialIconsRef = ref(
    Object.keys(socialIcons).filter((key) => key !== ''),
);
const { data: references, refresh: refreshReferences } = useFetch<
    ReferenceInterface[]
>(`/api/references/${props.assistant.slug}`);

const vocabulary: Ref<VocabularyItem[]> = useState('vocabulary', () => []);
const fetchVocabulary = async () => {
    const { data } = await useFetch<VocabularyItem[]>('/api/vocabulary');
    vocabulary.value = data.value ?? [];
};

await fetchVocabulary();

onMounted(async () => {
    assistantFilteredTagKeys.value = props.assistant.tags.map((tag) =>
        typeof tag === 'object' ? tag.tag.key : tag,
    );
    assistantLinks.value = props.assistant.links.filter(
        (link) => link.url !== undefined && link.url !== '',
    );
    for (const filter of (await updatedFiltersWithTags()).flatMap(
        (x) => x.filters,
    )) {
        if (filter?.items === undefined) {
            continue;
        }

        const selectedItems = [];
        for (const filterItem of filter.items) {
            if (assistantFilteredTagKeys.value.includes(filterItem.id)) {
                selectedItems.push(filterItem);
            }
        }

        if (selectedItems.length > 0) {
            assistantFilteredTags.value.push({
                ...filter,
                items: selectedItems,
            });
        }
    }
});

const loadingRecaptcha = ref(false);
const isGrecaptched = useState('isGrecaptched', () => false);
const onGrecaptcha = async (key: string) => {
    loadingRecaptcha.value = true;
    // wait a second for recaptcha to finish - pretty dumb it's not awaitable
    await new Promise((resolve) => setTimeout(resolve, 3000));
    try {
        const result = await $fetch<boolean>('/api/grecaptcha', {
            method: 'POST',
            body: JSON.stringify({
                recaptchaToken: key,
            }),
        });

        if (result) {
            isGrecaptched.value = true;
            loadingRecaptcha.value = false;
            return;
        }
    } catch (e) {
        console.error('Recaptcha failed', e);
    }

    error('Nepodařilo se ověřit, že nejste robot. Zkuste to prosím znovu.');
    isGrecaptched.value = false;
    loadingRecaptcha.value = false;
};
</script>

<template>
    <div class="drawer-profile mt-0 md:-mt-5 flex flex-col">
        <FavoriteButton class="absolute left-8 top-8" :assistant="assistant" tooltip-position="right" />
        <div class="flex flex-col md:flex-row justify-center items-center md:items-start pe-5 md:pe-0">
            <div class="mb-5 sm:mb-0">
                <div class="w-[250px] h-[240px] rounded flex align-middle justify-center">
                    <img
                        class="object-contain"
                        crossorigin="anonymous"
                        :src="assistant.image"
                        :alt="assistant.name"
                    />
                </div>
            </div>
            <div class="px-8 text-center sm:text-start flex flex-col md:h-[240px] justify-center">
                <h1 class="text-4xl">{{ assistant.name }}</h1>
                <p class="font-bold mb-3">{{ assistant.position }}</p>
                <GRecaptcha
                    :id="assistant.slug"
                    v-if="isGrecaptched === false"
                    :callback="onGrecaptcha"
                    :site-key="$config.public.recaptcha.v2SiteKey"
                >
                    <template #default="{ execute }">
                        <button
                            type="submit"
                            class="btn btn-primary btn-sm w-full mt-10"
                            :disabled="loadingRecaptcha"
                            @click="execute()"
                            rel="nofollow"
                        >
                            <span v-if="loadingRecaptcha" class="loading loading-spinner"></span>
                            <span v-else>Zobrazit kontaktní údaje</span>
                        </button>
                        <div class="text-center text-xs text-gray-500 mt-2 mb-8">
                            Chráněno službou reCAPTCHA.
                            <br>
                            <a
                                class="link"
                                href="https://policies.google.com/privacy?hl=cs"
                                target="_blank"
                            >
                                Ochrana soukromí
                            </a>&nbsp;|&nbsp;<a
                                class="link"
                                href="https://policies.google.com/terms?hl=cs"
                                target="_blank"
                            >
                                Smluvní podmínky
                            </a>
                        </div>
                    </template>
                </GRecaptcha>
                <template v-else>
                    <p class="!mb-0.5"><a class="link-hover" :href="`tel:${assistant.phone}`">{{ assistant.phone }}</a></p>
                    <p class="!mb-0.5"><a class="link-hover" :href="`mailto:${assistant.email}`">{{ assistant.email }}</a></p>
                    <p class="!mb-0.5">{{ assistant.city }}</p>
                    <p class="!mb-0.5">IČO: {{ assistant.ic }}</p>
                </template>
                <p class="mt-2">
                    <span v-for="(link, index) in assistantLinks" :key="link.url">
                        <a class="link-hover" target="_blank" :href="link.url">
                            <template v-if="socialIconsRef.includes(link.icon)">
                                <img
                                    :src="'/social-icons/' + link.icon + '.svg'"
                                    :alt="link.name"
                                    class="inline w-[25px] h-auto" />
                            </template>
                            <template v-else>
                                {{ link.name }}
                            </template>
                        </a>
                        <span v-if="index < assistantLinks.length - 1"> | </span>
                    </span>
                </p>
            </div>
        </div>
        <div class="divider mt-8"></div>
        <div>
            <p class="px-5 text-center" v-html="useTooltips(assistant.caption, vocabulary)"></p>
        </div>
        <div class="divider mt-5"></div>
        <div>
            <div class="flex flex-col lg:flex-row">
                <div class="w-full lg:w-2/3 pe-8">
                    <div class="prose" v-html="useTooltips(assistant.description, vocabulary)"></div>
                </div>
                <div class="w-full lg:w-1/3">
                    <h2 class="text-2xl mb-2">
                        Délka spolupráce
                        <span class="tooltip tooltip-left" data-tip="Co znamenají jednotlivé štítky?">
                            <NuxtLink
                                to="/nabizene-sluzby/zpusob-spoluprace-s-asistentkou-z-hlediska-delky"
                                target="_blank"
                                class="btn btn-xs relative -top-1 btn-circle"
                            >?</NuxtLink>
                        </span>
                    </h2>
                    <p class="mb-8">
                        <span
                            v-if="assistantFilteredTagKeys.includes('spoluprace-1')"
                            class="badge badge-primary me-2 h-auto min-h-[1.25rem]"
                        >dlouhodobá</span>
                        <span
                            v-if="assistantFilteredTagKeys.includes('spoluprace-2')"
                            class="badge badge-primary me-2 h-auto min-h-[1.25rem]"
                        >krátkodobá</span>
                        <span
                            v-if="assistantFilteredTagKeys.includes('spoluprace-3')"
                            class="badge badge-primary me-2 h-auto min-h-[1.25rem]"
                        >jednorázová</span>
                    </p>
                    <h2 class="text-2xl mb-2">Kategorie činností</h2>
                    <div class="mb-8">
                        <p
                            v-for="filter in assistantFilteredTags"
                            :key="'filterKey-' + filter.id"
                            class="mb-2"
                        >
                            <span class="badge badge-primary badge-lg me-2 h-auto min-h-[1.25rem]">{{ filter.label }}</span>
                            <span
                                v-for="item in filter.items"
                                :key="'tagsKey-' + item.id"
                                class="badge badge-secondary me-2 h-auto min-h-[1.25rem]"
                            >{{ item.label }}</span>
                        </p>
                    </div>
                    <template v-if="assistant.languages">
                        <h2 class="text-2xl mb-2">
                            Jazykové zkušenosti
                        </h2>
                        <p class="mb-8">
                            {{ assistant.languages }}
                        </p>
                    </template>
                    <h2 class="text-2xl mb-2">
                        Cena
                        <span class="tooltip tooltip-left" data-tip="Informace o hodinové sazbě asistentek">
                            <NuxtLink
                                to="/nabizene-sluzby/kolik-to-stoji-virtualni-asistentka"
                                target="_blank"
                                class="btn btn-xs relative -top-1 btn-circle"
                            >?</NuxtLink>
                        </span>
                    </h2>
                    <p class="mb-8">
                        <template v-if="assistant.priceFrom === assistant.priceTo || assistant.priceFrom === 0 || assistant.priceFrom === null">
                            {{ assistant.priceTo }}
                        </template>
                        <template v-else-if="assistant.priceTo === 0 || assistant.priceTo === null">
                            {{ assistant.priceFrom }}
                        </template>
                        <template v-else>
                            {{ assistant.priceFrom }} - {{ assistant.priceTo }}
                        </template>
                        Kč/hod.
                    </p>
                    <template v-if="assistant.capacity !== null">
                        <h2 class="text-2xl mb-2">
                            Kapacita
                        </h2>
                        <p class="mb-8">
                            <span v-if="assistant.capacity === 0">
                                Asistentka je momentálně plně vytížená
                            </span>
                            <span v-else>
                                Dostupná až {{ assistant.capacity }} {{ plural(assistant.caption, 'hodinu', 'hodiny', 'hodin') }} týdně
                            </span>
                        </p>
                    </template>
                    <div class="prose" v-html="useTooltips(assistant.sideDescription, vocabulary)"></div>
                </div>
            </div>
        </div>
        <div class="prose" v-html="useTooltips(assistant.content, vocabulary)"></div>
        <div class="divider mt-5"></div>
        <References :references="references ?? []" />
        <ReferenceForm :catalog="assistant" @sent="refreshReferences" />
        <p class="text-center text-xs pt-[40px]">
            Za tuto webovou prezentaci (a informace v ní zveřejněné) nese odpovědnost {{ assistant.name }}, ne autorka webu.
        </p>
    </div>
</template>

<style lang="postcss">
.drawer-profile {
    h2 {
        @apply text-2xl mb-2;
    }

    h3 {
        @apply text-xl mb-2;
    }

    h4 {
        @apply text-lg mb-2;
    }

    p, ul, ol {
        @apply mb-5;
    }

    li {
        p {
            @apply mb-1;
        }
    }
}
</style>
