export const socialIcons = {
    '': 'Bez ikony',
    facebook: 'Facebook',
    x: 'X (Twitter)',
    instagram: 'Instagram',
    youtube: 'YouTube',
    linkedin: 'Linked In',
    tiktok: 'Tik Tok',
    web: 'Osobní stránky',
};
