import type { Profile } from '~/types/profileCard';

const getFavorites = (): string[] => {
    try {
        return JSON.parse(localStorage.getItem('favorites') ?? '[]');
    } catch (e) {
        console.error(e);
        localStorage.removeItem('favorites');
        return [];
    }
};

export const setFavorite = (assistant: Profile): void => {
    const favorites = getFavorites();
    favorites.push(assistant.id as string);
    localStorage.setItem('favorites', JSON.stringify([...new Set(favorites)]));
    assistant.isFavorite = true;
};

export const removeFavorite = (assistant: Profile): void => {
    const favorites = getFavorites();
    localStorage.setItem(
        'favorites',
        JSON.stringify(favorites.filter((f) => f !== assistant.id)),
    );
    assistant.isFavorite = false;
};

export const isFavorite = (assistant: Profile): boolean => {
    const favorites = getFavorites();
    return favorites.includes(assistant.id as string);
};
