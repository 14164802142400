<script setup lang="ts">
import HeroIcon from '~/components/ui/HeroIcon.vue';
import { removeFavorite, setFavorite } from '~/utils/client/favoriteAssistant';

enum TooltipPosition {
    top = 'tooltip-top',
    right = 'tooltip-right',
    bottom = 'tooltip-bottom',
    left = 'tooltip-left',
}

withDefaults(
    defineProps<{
        assistant: {
            isFavorite: boolean;
        };
        tooltipPosition?: TooltipPosition;
    }>(),
    {
        tooltipPosition: TooltipPosition.top,
    },
);
</script>

<template>
    <div>
        <span
            v-if="assistant.isFavorite"
            class="tooltip cursor-pointer"
            :class="TooltipPosition[tooltipPosition]"
            data-tip="Odebrat z oblíbených"
            @click.stop="removeFavorite(assistant)"
        >
            <HeroIcon name="HeartIcon" class="w-6 h-6 text-secondary" solid/>
        </span>
        <span
            v-else
            class="tooltip cursor-pointer"
            :class="TooltipPosition[tooltipPosition]"
            data-tip="Přidat do oblíbených"
            @click.stop="setFavorite(assistant)"
        >
            <HeroIcon name="HeartIcon" class="w-6 h-6 text-primary"/>
        </span>
    </div>
</template>
