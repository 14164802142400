<script setup lang="ts">
import Reference, {
    type ReferenceInterface,
} from '~/components/ui/Reference.vue';

defineProps<{
    references: ReferenceInterface[];
}>();
</script>

<template>
    <div>
        <div class="text-center" v-if="references.length === 0">
            Zatím nebylo přidáno žádné hodnocení.
        </div>
        <Reference
            v-for="reference in references"
            :key="`${reference.name}-${reference.date}`"
            :name="reference.name"
            :image="reference.image"
            :position="reference.position"
            :date="reference.date"
            :message="reference.message"
            :note="reference.note"
        />
    </div>
</template>

<style scoped lang="postcss">

</style>
